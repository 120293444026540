import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { IRole } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IRole";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { checkExistLibrary, getFullPath, getQuery, encodeQueryData } from "@monorepo/utils/src/api/utils";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { IUser } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IUser";
import { changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import { cloneDeep } from "lodash";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { convertFiltersCustomToApi } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/utils/convertFiltersToApi";
import { IUserListCardElement } from "@monorepo/utils/src/types/IUserListCardElement";
import axios from "axios";
import { IAuthorityGroup } from "@monorepo/authorization/src/views/Login/types/authResponse";
import { formRoles } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/utils/formRoles";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { sortTableOnlyDateWithNonDate } from "@monorepo/utils/src/utils/sortTableOnlyDateWithNonDate";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/fundView/elementStatuses";
import { convertFiltersCustomToApi as convertFiltersArchiveToApi } from "@monorepo/catalog/src/views/ArchiveView/utils/convertFiltersToApi";
import { IArchiveHierarchyElement } from "@monorepo/catalog/src/store/modules/archiveCatalogView";

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

interface IAccessState {
  searchTemplates: ISearchTemplate[];
  roles: IRole[]; // дефолтный список ролей
  localRoles: IRole[]; // рабочий список ролей
  currentRoles: { id: number; label: string }[]; //  селекте в карточке ролей
  authorities: IAuthorityGroup[];
  filters: ITableFiltersObject;
  users: IUser[];
  totalUsers: number | null;
  totalRoles: number | null;
  isOpenFilters: boolean;
  totalLocalRoles: number | null;
  section?: Sections;
  cells: string[];
  sectionCells: ISection;
  infiniteId: string;
  isTableLoading: boolean;
  defaultTwoStepsAuth: boolean;
  libraries: {
    funds: string[];
    oik: IOikCardElement[];
    archives: IArchiveHierarchyElement[];
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null, // opened modal for Roles
  openedUserId: null, // opened modal for Users
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const archiveFilters = (): ITableFiltersObject => ({
  sort: {
    code: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 5000,
});

export const module: Module<IAccessState, IRootState> = {
  namespaced: true,
  state: (): IAccessState => ({
    roles: [],
    currentRoles: [],
    authorities: [],
    section: Sections.EXTERNAL_SYSTEM_LIST,
    searchTemplates: [],
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    isOpenFilters: false,
    localRoles: [],
    users: [],
    cells: [], // working with user table
    sectionCells: {} as ISection, // working with user table
    totalUsers: null,
    totalRoles: null,
    totalLocalRoles: null,
    isTableLoading: false,
    defaultTwoStepsAuth: false,
    libraries: {
      funds: [],
      oik: [],
      archives: [],
    },
  }),
  mutations: {
    ...baseMutations,
    funds(state: IAccessState, payload: string[]) {
      state.libraries.funds = payload;
    },
    addOikLib(state: IAccessState, payload: IOikCardElement[]) {
      state.libraries.oik =
        payload.map((oik) => {
          return { ...oik, label: oik.shortName };
        }) || [];
    },
    addArchives(state: IAccessState, payload: IArchiveHierarchyElement[]) {
      state.libraries.archives = (payload || []).reduce((result: IArchiveHierarchyElement[], item: IArchiveHierarchyElement) => {
        if (!item.isDeleted) {
          item.funds = item.funds.filter((fond) => !fond.isDeleted).map((fond) => ({ ...fond, parentId: item.id }));
          result = result.concat(item);
        }
        return result;
      }, []);
    },
    setFilters(state: IAccessState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    addSort(state: IAccessState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = sortTableOnlyDateWithNonDate(state.filters.sort, payload, ["createDate", "lastLoginTime"]);
    },
    setRoles(state: IAccessState, payload: { data: IRole[]; total: number }) {
      state.roles = payload.data;
      state.localRoles = cloneDeep(payload.data);
      state.totalRoles = payload.total;
      state.totalLocalRoles = payload.total;
    },
    clearFilters(state: IAccessState) {
      state.filters = defaultFilters();
    },
    setAuthorities(state: IAccessState, payload: IAuthorityGroup[]) {
      state.authorities = payload;
    },
    setCurrentRoles(state: IAccessState, payload: { data: { id: number; label: string }[] }) {
      state.currentRoles = payload.data;
    },
    setLocalRoles(state: IAccessState, payload: { data: IRole[]; total: number }) {
      state.localRoles = cloneDeep(payload.data);
      state.totalLocalRoles = payload.total;
    },
    addOpenedUserId(state: IAccessState, payload: null | string | number) {
      state.filters.openedUserId = payload;
    },
    changeOpenedUserId(state: IAccessState, payload: changeOpenedIdPayloadValues) {
      const selectedIndex = state.users.findIndex((element) => element.id === state.filters.openedUserId);

      if (payload === changeOpenedIdPayloadValues.UP) {
        state.filters.openedUserId = selectedIndex === 0 ? state.users[state.users.length - 1].id : state.users[selectedIndex - 1].id;
      }

      if (payload === changeOpenedIdPayloadValues.DOWN) {
        state.filters.openedUserId = selectedIndex === state.users.length - 1 ? state.users[0].id : state.users[selectedIndex + 1].id;
      }
    },
    setUsers(
      state: IAccessState,
      payload: {
        data: IUser[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.users = payload.isReset ? payload.data : state.users.concat(payload.data);
      state.totalUsers = payload.totalLength;
    },
    addRole(state: IAccessState, payload: IRole) {
      state.roles.push(payload);
    },
    removeRole(state: IAccessState, id: string) {
      state.roles = state.roles.filter((item) => item.id !== id);
    },
    refreshUsersData(state: IAccessState) {
      state.users = [];
    },
    // blockUser(state: IAccessState, payload: string) {
    //   state.users = state.users.map((user) => {
    //     if (user.id === payload) {
    //       return {
    //         ...user,
    //         active: false,
    //       };
    //     } else {
    //       return user;
    //     }
    //   });
    // },
    // unblockUser(state: IAccessState, payload: string) {
    //   state.users = state.users.map((user) => {
    //     if (user.id === payload) {
    //       return {
    //         ...user,
    //         active: true,
    //       };
    //     } else {
    //       return user;
    //     }
    //   });
    // },
    setTableLoading(state: IAccessState, payload: boolean) {
      state.isTableLoading = payload;
    },
    setDefaultTwoStepsAuth(state: IAccessState, payload: boolean) {
      state.defaultTwoStepsAuth = payload;
    },
  },
  actions: {
    ...baseActions,
    getFunds({ commit, state }) {
      checkExistLibrary(state.libraries.funds, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_FUND_SHORT_ITEM_LIB);
        commit("funds", data || []);
      });
    },
    addOpenedId({ commit }, payload: null | string | number) {
      commit("addOpenedId", payload);
    },
    async getUserCardElement(info, id: string | number) {
      const { data } = await getQuery<IUserListCardElement>(`${QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST}/${id}`);
      return data;
    },
    async getAutocompleteByLogin(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + `/by-username/like`,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: { identityName: string }) => ({
          ...item,
          titleValue: item.identityName,
        }));
      }
      return [];
    },
    async getAutocompleteByFullName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + `/by-fullName/like`,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: { fullName: string }) => ({
          ...item,
          titleValue: item.fullName,
        }));
      }
      return [];
    },
    async getAutocompleteByEmail(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + `/by-email/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { email: string }) => ({
          ...item,
          titleValue: item.email,
        }));
      }
      return [];
    },
    async getRoleUsers(info, { id, offset }: { id: string; offset: number }) {
      const { data, total: totalLength } = await getQuery<IUser[]>(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${id}/users`, {
        limit: info.state.filters.initMessagesLength,
        offset,
      });
      return { data, totalLength };
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        const { data: resultData } = await axios[!data.id ? "post" : "put"](
          `${QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST}${!data.id ? "" : `/${data.id}`}`,
          data
        );
        return resultData;
      } catch (e) {
        showNotification(
          "Ошибка создания пользователя: " +
            ((e as unknown as error).response?.data?.error?.text || (e as unknown as error).response?.data?.message || "")
        );
        return false;
      }
    },
    addOpenedUserId({ commit }, payload: null | string) {
      commit("addOpenedUserId", payload);
    },
    changeOpenedUserId({ commit }, payload: changeOpenedIdPayloadValues) {
      commit("changeOpenedUserId", payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetPassword(info, id: string) {},
    async deleteRoleQuery(info, id: string) {
      return await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${id}`));
    },
    async blockUser(info, { id, blockUntil }: { id: number; blockUntil: string }) {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST}/${id}/lock`), { blockUntil });
        info.commit("blockUser", id);
        return true;
      } catch (e) {
        showNotification("Ошибка блокировки пользователей");
        return false;
      }
    },
    async unblockUser(info, id: string) {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST}/${id}/unlock`));
        info.commit("unblockUser", id);
        return true;
      } catch (e) {
        showNotification("Ошибка разблокировки пользователей");
        return false;
      }
    },
    async saveRoles(info, roles: { id: string | number }[]) {
      await Promise.all(
        roles.map((role) => {
          if (!role.id) {
            return axios.post(getFullPath(QUERY_PATH.AUTHENTICATION_ROLE_LIST), role);
          } else {
            return axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${role.id}`), role);
          }
        })
      )
        .then((results) => {
          const isSuccess = results.every((result) => result.status === 200);
          if (isSuccess) {
            showNotification("Настройки сохранены.", NOTIFICATION_STATUS.SUCCESS);
          }
        })
        .catch(function (error) {
          showNotification(error.response.data.message);
        });
      await info.dispatch("getRoles");
    },
    async deleteUserFromRole(info, payload: { roleId: string; userId: string }) {
      return await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${payload.roleId}/users/${payload.userId}`));
    },
    async deleteRoles({ dispatch }, payload: string) {
      await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${payload}`));
      dispatch("getRoles");
    },
    async getRoles({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      let authoritiesGroups = state.authorities || [];
      if (!authoritiesGroups?.length) {
        const { data } = await getQuery<IAuthorityGroup[]>(QUERY_PATH.AUTHENTICATION_ROLE_AUTHORITIES_GROUPS_LIST);
        authoritiesGroups = data;
        commit("setAuthorities", authoritiesGroups);
      }

      const { data: rolesList } = await getQuery<IRole[]>(QUERY_PATH.AUTHENTICATION_ROLE_LIST);
      const roles = formRoles(rolesList, authoritiesGroups);
      commit("setRoles", roles);
      commit("setTableLoading", false);
      return roles;
    },
    async getUserList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.users.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IUser[]>(QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("setUsers", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.users || [], totalLength: state.totalUsers || 0 };
      }
      return { data: null };
    },
    async refreshUserList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.users.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IUser[]>(QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST, params);
      if (data !== null) {
        commit("setUsers", { data: data || [], totalLength: totalLength || 0, isReset: true });
        return { data: state.users || [], totalLength: state.totalUsers || 0 };
      }
      return { data: null };
    },
    getExportUsers({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.users.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch(
        "app/openNewWindow",
        getFullPath(
          payload === "CSV"
            ? QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + "/export/csv"
            : payload === "XLSX"
            ? QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + "/export/xls"
            : QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_LIST + "/export/pdf",
          queryParams
        ),
        {
          root: true,
        }
      );
    },
    async getCurrentRolesList({ commit }) {
      // const { data } = await getQuery<IRole[]>(QUERY_PATH.AUTHENTICATION_ROLE_LIST);
      // if (data !== null) {
      //   commit("setCurrentRoles", { data });
      //   return data;
      // }
      // return { data: [] };
      commit("setCurrentRoles", { data: [{ id: 1, label: "Внешняя система" }] });
    },
    addRole({ commit }, role) {
      commit("addRole", role);
    },
    removeRole({ commit }, id) {
      commit("removeRole", id);
    },
    refreshScroll({ commit }) {
      commit("refreshUsersData");
      setTimeout(() => {
        // TODO rewrite, error with infinite scroll this.$refs.infiniteLoader.getCurrentDistance() not refreshed to [] data
        commit("refreshInfiniteId");
      }, 100);
    },
    async getDefaultTwoStepsAuth({ commit }) {
      const { data } = await getQuery<{ data: boolean }>(QUERY_PATH.GET_TWO_STEPS_AUTH_SETTING);
      commit("setDefaultTwoStepsAuth", data ?? false);
    },
    async getArchiveHierarchy({ commit, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}`).finally(() => {
        commit("setTableLoading", false);
      });
      return data;
    },
    async getArchiveHierarchyLib({ commit }) {
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}`);
      commit("addArchives", data);
    },
    getOiks({ commit, state }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      checkExistLibrary(state.libraries.oik, async () => {
        const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, resultParams);
        commit("addOikLib", data || []);
      });
    },
    async getUserAccesses(info, { userId }: { userId: string }) {
      const { data } = await getQuery<IArchiveElement[]>(`${QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_ACCESSES}/${userId}`);
      return data || { archiveIds: [], fundIds: [], oikIds: [] };
    },
    async setUserAccesses(info, payload: { externalSystemId: string; fundIds: string[]; archiveIds: string[]; oikIds: string[] }) {
      try {
        await axios.post(QUERY_PATH.AUTHENTICATION_EXTERNAL_USER_ACCESSES, payload);
      } catch (error) {
        showNotification(error.response.data.message);
      }
    },
  },
  getters: {
    ...baseGetters,
    roles: (state: IAccessState) => state.roles,
    currentRoles: (state: IAccessState) => state.currentRoles,
    localRoles: (state: IAccessState) => state.localRoles,
    authorities: (state: IAccessState) => state.authorities,
    selectedIds: (state: IAccessState) => state.filters.selectedIds,
    users: (state: IAccessState) => state.users || [],
    totalUsers: (state: IAccessState) => state.totalUsers || 0,
    totalRoles: (state: IAccessState) => state.totalRoles || 0,
    totalLocalRoles: (state: IAccessState) => state.totalLocalRoles || 0,
    openedUserId: (state: IAccessState) => state.filters.openedUserId,
    isTableLoading(state: IAccessState) {
      return state.isTableLoading;
    },
    funds(state: IAccessState) {
      return state.libraries.funds || [];
    },
    defaultTwoStepsAuth: (state: IAccessState) => state.defaultTwoStepsAuth,
    oiks: (state: IAccessState) => state.libraries.oik || [],
    archivesTree: (state: IAccessState) => state.libraries.archives || [],
  },
};
